import React, { MouseEvent } from 'react';
import { IMenu } from '@/interfaces/menu';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { animateScroll as scroll } from 'react-scroll';

const HorizontalMenuItem = ({ slug, title, menus }: IMenu) => {
  const { query } = useRouter();
  return (
    <div>
      <div className="cursor-pointer py-5 uppercase group hover:bg-gray-200">
        <Link
          href={`/${slug}`}
          className={`p-5 hover:text-primary ${
            query.slug === slug ? 'text-primary font-semibold' : ''
          }`}
        >
          {title}
        </Link>

        <div className="absolute right-0 left-0 mt-5 hidden bg-gray-100 group-hover:flex">
          <div className="container flex flex-col px-10 lg:flex-row">
            {menus?.map((sub, index) => (
              <SubMenuItem parentSlug={slug} {...sub} key={index} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export interface ISubMenuItem extends IMenu {
  parentSlug: string;
}
const SubMenuItem = ({ slug, title, parentSlug }: ISubMenuItem) => {
  const router = useRouter();
  const handleClick = (slug: string) => {
    const element = document.getElementById(slug);
    if (element) {
      const offset = element.offsetTop - 160;
      scroll.scrollTo(offset, {
        duration: 500,
        smooth: 'easeInOutQuart',
      });
    }
    const url = `/${parentSlug}#${slug}`;
    router.push(url, undefined, { scroll: false });
  };
  return (
    <div className="px-5 py-3 normal-case decoration-1 underline-offset-8 hover:underline lg:py-5 hover:text-primary">
      <div
        onClick={() => {
          handleClick(slug);
        }}
      >
        {title}
      </div>
    </div>
  );
};

export default HorizontalMenuItem;
